<template>
    <div ref="emotionGraph" style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themesAnimated);

export default {
    props: {
        xyData: {
            type: Array,
            required: true,
        },
        currentTime: {
            type: Number,
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        currentTime(newTime) {
            if (this.currentTimeLine) {
                this.currentTimeLine.value = newTime;
            }
        }
    },
    methods: {
        init() {
            // Create chart instance
            const chart = am4core.create(this.$refs.emotionGraph, am4charts.XYChart);

            // Increase contrast by taking every second color
            chart.colors.step = 2;

            // Convert data timestamps from ms to seconds
            const convertedData = this.xyData.map(dataPoint => {
                const newDataPoint = { ...dataPoint };
                newDataPoint.timestamp = newDataPoint.timestamp / 1000; 
                return newDataPoint;
            });


            // Assign data
            chart.data = convertedData;

            // Create axes
            const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
            xAxis.title.text = "Timestamp (Seconds)";

            const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.title.text = "";

            // Set the exact range of the x-axis to match the video duration
            xAxis.min = 0; // Start at 0 seconds
            // xAxis.max = 6.5; // End at the exact duration of the video

            // Avoid rounding or extending the axis
            xAxis.strictMinMax = true;
            // xAxis.renderer.minGridDistance = 30; 
            


            // Create series for each emotion
            this.createSeries(chart, "attention_score", "Attention");
            this.createSeries(chart, "clarity_score", "Clarity");
            // this.createSeries(chart, "focus_score", "Focus");
            this.createSeries(chart, "mental_demand", "Mental demand");
            // this.createSeries(chart, "spread_score", "Spread");
            // this.createSeries(chart, "engagement", "Engagement");
            // this.createSeries(chart, "excitement", "Excitement");
            // this.createSeries(chart, "fear", "Fear");
            // this.createSeries(chart, "sadness", "Sadness");

            // Add legend
            chart.legend = new am4charts.Legend();

            // Add cursor
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "none"; // Ensure cursor doesn't zoom/pan


            // Add vertical line
            this.currentTimeLine = xAxis.axisRanges.create();
            this.currentTimeLine.grid.stroke = am4core.color("#FF0000");
            this.currentTimeLine.grid.strokeWidth = 2;
            this.currentTimeLine.grid.strokeOpacity = 0.6;
            this.currentTimeLine.label.inside = true;
            // this.currentTimeLine.label.text = "Current Time";
            this.currentTimeLine.label.fill = this.currentTimeLine.grid.stroke;
            this.currentTimeLine.label.verticalCenter = "bottom";
            this.currentTimeLine.label.dy = -10;
            this.currentTimeLine.value = this.currentTime;

            // Add event listener for cursor position change
            chart.cursor.events.on("cursorpositionchanged", this.updateCurrentTime);

            this.chart = chart; // Save reference to chart

        },

        // Create series
        createSeries(chart, field, name) {
            const series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueX = "timestamp";
            series.dataFields.valueY = field;
            series.name = name;
            series.strokeWidth = 2;
            series.tensionX = 0.8;
            // series.bullets.push(new am4charts.CircleBullet());
            series.tooltipText = "{name}: [bold]{valueY}[/]";
            series.legendSettings.valueText = "{valueY}";
        },

        updateCurrentTime(event) {
            const xAxis = this.chart.xAxes.getIndex(0);
            const cursorX = this.chart.cursor.xPosition;
            const timestampInSeconds = xAxis.positionToValue(cursorX);
            // const timestampInMs = timestampInSeconds * 1000;
            this.$emit("updateCurrentTime", timestampInSeconds);
        }
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}
</script>

<style scoped>
#chartdiv {
    width: 100%;
    height: 500px;
}
</style>

<template>
  <div class="image-container">
    <!-- Base image (always visible) -->
    <img
      :src="imagesUrls.resource_path"
      alt="Base image"
      class="base-image"
    >
    <!-- Overlay images -->
    <img
      v-for="(image, key) in overlayImages"
      :key="key"
      :src="image"
      :alt="key"
      class="overlay-image"
      v-show="activeImage === key"
    >
  </div>
</template>

<script>
export default {
  props: {
    activeImage: {
      type: String,
      default: 'resource_path',
      validator: prop => ['resource_path', 'heatmap_path', 'gazeplot_path', 'fogmap_path'].includes(prop)
    },
    imagesUrls: {
      type: Object,
      required: true,
      default: () => ({
        resource_path: '',
        heatmap_path: '',
        gazeplot_path: '',
        fogmap_path: ''
      })
    }
  },
  computed: {
    overlayImages() {
      const { resourcePath, ...rest } = this.imagesUrls;
      return rest;
    }
  }
}
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  max-height: 420px;
  border-radius: 10px;
  overflow: hidden;
}

.base-image,
.overlay-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 420px;
  background-color: transparent;
}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
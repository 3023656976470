<template >
    <div ref="chartdiv" class="donut" >
        
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
export default {
    props:{
        piechartData:{
            type:Array,    // value,label,color
            required:true,
        }
    },
    data(){
        return {

        }
    },
    mounted(){

        this.init();
    },
    methods:{
        init(){
            const chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
            chart.legend = new am4charts.Legend();
            chart.legend.scrollable = true;
            // chart.legend.maxHeight = 150;
            
            // make legend round
            chart.legend.useDefaultMarker = true;
            const marker = chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;

            chart.innerRadius = am4core.percent(20);
            chart.data=this.piechartData;
            chart.radius = am4core.percent(85);
            
            
            const pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "label";
            pieSeries.labels.template.disabled = true;
            
            pieSeries.slices.template.propertyFields.fill = "color";
            
            
        }
    },
}
</script>

<style scoped>
.donut{
    margin-top: 20px;
    padding:10px;
    width: 100%;
    height: 500px;
    overflow: auto;
}
</style>
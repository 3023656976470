<template>
  <div class="slider-container">
    <div style="display: flex;">
      <title-info :condensed="this.condensed" justify="start" :size="this.labelSize" :mb="2" :info="toolTip">
        {{ label }}
      </title-info>
      <span style="flex: 1; text-align: right; font-weight: bold;">{{ this.score>0 && this.variant==='gradient'?'+':'' }} {{!this.hideScore? this.score:"" }}</span>
    </div>
    <div class="slider-wrapper">
      <input
        type="range"
        :min="this.min"
        :max="this.max"
        v-model="score"
        @input="updateGradientSlider"
        @mouseover="showGradientBubble = true"
        @mouseleave="showGradientBubble = false"
        :style="gradientSliderStyle"
        disabled
        :class="variantClass"
      />
      <div class="benchmark-indicator" :style="benchmarkIndicatorStyle" v-if="benchmark>0">
        <div class="arrow down"></div>
        <div class="line"></div>
        <div class="arrow up"></div>
      </div>
    </div>
    <div style="width: 100%; display: flex; justify-content: space-between;">
      <span style="flex: 1; text-align: left; color:#717D96">{{ negative }}</span>
      <span style="flex: 1; text-align: center; color:#717D96" v-if="variant==='gradient'">{{ neutral }}</span>
      <span style="flex: 1; text-align: right; color:#717D96">{{ positive }}</span>
    </div>
    <div class="bubble-wrapper" v-if="showGradientBubble && benchmark>0" :style="bubbleWrapperStyle">
      <div class="bubble">
        <div class="bubble-pointer"></div>
        <div class="bubble-content">
          {{ $t("page.results.score.heatmap.toolTip.benchmark") }} {{ benchmark }}/100
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleInfo from "../Score/TitleInfo.vue";
export default {
  components: {
    TitleInfo,
  },
  data() {
    return {
      showBubble: false,
      showGradientBubble: false,
      showMoreAttention: false,
      showMoreEngagement: false,
      gradientSliderStyle: {},
      benchmarkIndicatorStyle:{},
      bubbleWrapperStyle: {},
    };
  },
  props: {
    score: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "purple",
      validator: (prop) => ["purple", "orange", "gradient"],
    },
    min:{
      type:Number,
      default:0
    },
    max:{
      type:Number,
      default:100,
    },
    benchmark:{
      type:Number,
      default:0
    },
    toolTip:{
      type:String,
      default:''
    },
    hideScore:{
      type:Boolean,
      default:false,
    },
    labelSize:{
      type:String,
      default:'small'
    },
    condensed:{
      type:Boolean,
      default:true,
    }
  },
  computed: {
    variantClass() {
      return this.variant === 'gradient' ? 'showRangeThumb' : 'hideRangeThumb';
    },
    negative(){
      return this.variant === 'gradient' ? this.$t('page.results.score.heatmap.scoreBars.negative') : 0
    },
    neutral(){
      return this.$t("page.results.score.heatmap.scoreBars.neutral")
    },
    positive(){
      return this.variant ==='gradient'? this.$t("page.results.score.heatmap.scoreBars.positive"):100
    }
  },
  methods: {
    updateGradientSlider() {
      const value = this.score;
      const percentage = value;
      const variant = this.variant;
      let variantColor= ''
      if(variant==='gradient'){
        this.gradientSliderStyle = {
          background: 'linear-gradient(to right, #FF0000, #FFD600, #00F336)',
        };

      }else{
        switch(this.variant){
          case 'orange':
              variantColor='#FE9D35'
            break;
          case 'purple':
          default:
              variantColor="#985EFF"

        }
        this.gradientSliderStyle = {
          background: `linear-gradient(to right, ${variantColor} ${percentage}%, #ddd ${percentage}%)`,
          };
      }
    },
    updateBenchmarkIndicator() {
    const percentage = (this.benchmark / this.max) * 100;
    this.benchmarkIndicatorStyle = {
      left: `calc(${percentage}% - 1px)`,
    };
    this.bubbleWrapperStyle = {
      left: `${percentage}%`,
    };
  },
    
  },
  mounted() {
    this.updateGradientSlider();
    this.updateBenchmarkIndicator();
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.slider-wrapper {
  position: relative;
  width: 100%;
}

input[type="range"] {
  width: 100%;
  height:11px;
  -webkit-appearance: none;
  background: #ddd;
  outline: none;
  opacity: 1;
  border-radius: 10px;
}

input[type="range"].showRangeThumb::-moz-range-thumb {
  width:11px;
  height:11px;
  background: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"].hideRangeThumb::-moz-range-thumb {
  width: 0;
  height: 0;
  opacity: 0;
  background: transparent;
  border: none;
}

input[type="range"].hideRangeThumb::-webkit-slider-thumb{
  display: none;
}



.benchmark-indicator {
  position: absolute;
  top: 0px;
  width: 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.line {
  width: 2px;
  height: 100%;
  background-color: #333;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.arrow.up {
  border-bottom: 5px solid #333;
}

.arrow.down {
  border-top: 5px solid #333;
}


.bubble-wrapper {
  z-index: 999;
  position: absolute;
  top: 80%;
  transform: translateX(-50%);
}

.bubble {
  position: relative;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0.8;
}

.bubble-pointer {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333;
}

.bubble-content {
  padding: 5px;
}
</style>

